<template>
  <div class="c_main">
    <el-form ref="formData" :model="formData" :rules="formRule" label-width="140px">
      <el-form-item label="追加佣金：" prop="rushFee">
        <el-input-number
          v-model="formData.rushFee"
          :min="0"
          :precision="2"
          class="form-input"
          size="small"
          @change="refreshPrice"
        ></el-input-number>
        <span class="help">追加资金越多，更易被试客申请，效率越高！</span>
      </el-form-item>

      <el-form-item label="任务发布时间：" prop="publishTime">
        <el-date-picker
          v-model.trim="formData.publishTime"
          type="datetime"
          placeholder="选择日期时间"
          class="form-input"
          size="small"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="refreshPrice"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="任务发布间隔：" prop="publishInterval">
        <el-input-number v-model.trim="formData.publishInterval" class="form-input" size="small"></el-input-number>
        <span class="help">单位：分钟（间隔多长时间发布一个任务）</span>
      </el-form-item>

      <el-form-item label="是否保存模板：">
        <el-radio-group v-model="formData.needSaveTemplate">
          <el-radio :label="1">保存</el-radio>
          <el-radio :label="0">不保存</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="模板名称：" prop="templateName" v-if="formData.needSaveTemplate">
        <el-input v-model.trim="formData.templateName" class="form-input" size="small"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    forms: Array,
  },
  data() {
    return {
      formData: {
        rushFee: 0,
        needSaveTemplate: 0,
        templateName: "",
        publishTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        publishInterval: 1,
      },
      formRule: {
        rushFee: [{ required: true, message: "请输入追加佣金", trigger: "blur" }],
        publishTime: [{ required: true, message: "请选择发布时间", trigger: "blur" }],
        publishInterval: [{ required: true, message: "请输入发布间隔", trigger: "blur" }],
        templateName: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (Object.keys(this.forms[3].formData).length > 0) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.forms[3].formData[key];
      });
      this.formData.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {
    // 表单提交，父组件访问此方法，如果表单验证成功返回表单数据，否则返回null
    async submitForm(formName) {
      if (!formName) formName = "formData";
      try {
        const result = await this.$refs[formName].validate();
        if (result) {
          return this.formData;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    refreshPrice() {
      this.$emit("refreshPrice", this.formData);
    },
  },
};
</script>

<style lang="less" scoped>
.c_main {
  .form-input {
    width: 200px;
  }

  .help {
    padding: 0 1rem;
    color: #999;
  }
  .help_block {
    color: #999;
  }
}

@media (max-width: 520px) {
  .c_main {
    .help {
      display: block;
      padding: 0;
    }
  }
}
</style>
