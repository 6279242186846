<template>
  <div class="process-main">
    <div class="process-c-item" v-if="stepData.commentPromptType==1">
      请五星好评，不用写好评。
    </div>
    <div class="process-c-item" v-if="stepData.commentPromptType==2">
      请根据产品实际情况给出合理的评价，要合理不可以乱写。
    </div>
    <div class="process-c-item" v-if="stepData.commentPromptType==3">
      1.评价：<span>{{ stepData.commentPrompt }}</span> &nbsp;
      <el-button type="success" size="small" round style="margin-left: 10px;"
        @click="copy(stepData.commentPrompt)"
        >复制评价
      </el-button>
    </div>
    <div class="process-g-item" v-if="!stepData.noScreenShotMode">2.请上传评价截图</div>
    <div class="process-g-item" v-if="!stepData.noScreenShotMode" style="margin-top: 10px">
      <uploader v-model="data.image" :type="'dotask'"></uploader>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/uploader";
export default {
  components: {
    uploader,
  },
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue.image !== "") {
          this.$emit("update-done", newValue.image);
        }
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    if (this.stepData.noScreenShotMode) {
      setTimeout(() => {
        this.$emit("update-done", true);
      }, 0);
    } else {
      this.data.image = this.stepData.commentImgs || "";
    }
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      data: {
        image: "",
      },
    };
  },
  methods: {
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    handleAvatarSuccess(res, file) {
      this.image = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-c-item {
    margin-bottom: 1rem;
  }
}
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
