<template>
  <div>
    <div class="item">
      <div class="content">
        <div class="meta">
          <div class="thumb">
            <img :src="data.imgs.split(',')[0]" :alt="data.goodsName" />
          </div>

          <div class="pc-meta">
            <div class="margin-bottom-5px title text-lg font-bold">
              {{ data.goodsName }}
            </div>

            <!-- PC -->
            <div class="margin-bottom-5px price text-0.9rem font-bold">
              <div>
                下单总价：<span class="text-red-500" >{{ data.actualGoodsFee ? data.actualGoodsFee : data.goodsFee | priceFormat }}</span>
              </div>
              <div>
                悬赏奖励：<span class="text-red-500">{{ calcComission(data) | priceFormat }}</span>
              </div>
            </div>
          </div>

          <!-- Mobile -->
          <div class="mob-meta">
            <div class="price">
              <div>
                宝贝单价：<span class="text-red-500">{{ data.goodsPrice | priceFormat }}</span>
              </div>
              <div>
                下单数量：<span class="text-red-500">{{ data.goodsNum }}</span>
              </div>
            </div>
            <div class="price">
              <!-- 客户要求这里不显示totalAmount -->
              <div>
                下单总价：<span>{{ data.actualGoodsFee ? data.actualGoodsFee : data.goodsFee | priceFormat }}</span>
              </div>
              <div>
                悬赏奖励：<span>{{ calcComission(data) | priceFormat }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="margin-bottom-5px btns">
          <el-button plain size="small" @click="trialFun(false)">申请试用</el-button>
        </div>

        <div class="tags">
          <a-tag v-for="(tag, index) in computedData.tags" :key="index" :color="getTagColor(tag)">
            {{ tag }}
          </a-tag>
          <!-- <a-tag color="green"> 评价建议：{{ data.commentPrompt }} </a-tag>
          <a-tag color="red" style="line-height: 1.3rem"> 商家留言：{{ data.messagePrompt }} </a-tag> -->
        </div>

        <div class="mob-btns">
          <el-button type="primary" style="width: 100%" @click="trialFun(true)">申请试用</el-button>
        </div>
      </div>
    </div>

    <!-- 弹窗 START -->
    <el-dialog title="请选择一个买号申请免费试用" :visible.sync="trialDialog" :width="trialDialogWidth" center>
      <div v-loading="loadingTrial">
        <el-button
          style="margin-bottom: 0.5rem"
          plain
          @click="buyFun(item)"
          v-for="(item, index) in buyAccountList"
          :key="index"
          :type="item.buyType == 1 ? 'primary' : ''"
        >
          <div class="flex flex-row items-center h-20px">
            {{ item.account }}
            <TrialLevel class="ml-1" :platformType="data.platformType" :creditLevel="item.creditLevel" />
          </div>
        </el-button>
        <!-- 内部加载 -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="trialDialog = false">取消</el-button>
        <el-button type="primary" @click="$router.push('/buy')">添加买号</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗 END -->
  </div>
</template>

<script>
import TrialLevel from "./TrialLevel.vue";
import aTag from "@/components/tag/Tag.vue";
import { getBuyAccountList } from "@/api/buyAccount";
import { applyTrail } from "@/api/goods";
import filter from "@/plugin/filter";
const colors = ["blue", "red", "orange", "purple"];
export default {
  components: {
    aTag,
    TrialLevel,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      colors,
      trialDialog: false,
      trialDialogWidth: "",
      loadingTrial: false,
      buyAccountList: [],
    };
  },
  computed: {
    computedData() {
      const item = this.data;
      return {
        ...this.data,
        tags: filter.filterTags(item),
      };
    },
  },
  methods: {
    calcComission(item) {
      console.log('calcComission user ' + JSON.stringify(this.$store.state.user))
      if (this.$store.state.user == undefined || this.$store.state.user == '') {
        return item.buyerCommissionFee
      }
      if (this.$store.state.user.userRole == 'SELLER') {
        return item.buyerCommissionFee
      }
      if (this.$store.state.user.vipType == undefined || this.$store.state.user.vipType == '') {
        return item.buyerCommissionFee
      }
      var leftRate = 1 - this.$store.state.user.vipType.commissionServiceRate / 100
      // console.log('rate ' + this.$store.state.user.vipType.commissionServiceRate + ' leftRate ' + leftRate)
      var price = item.buyerCommissionFee * leftRate
      console.log('price ' + price)
      return price
    },
    getTagColor(tag) {
      const colors = this.$store.state.config.tagColors;
      if (colors && colors[tag] == undefined) {
        colors[tag] = this.colors[Math.floor(Math.random() * this.colors.length)];
      }
      return colors[tag];
    },
    // 点击试用按钮
    trialFun(isMobile) {
      if (isMobile) this.trialDialogWidth = "90%";
      else this.trialDialogWidth = "500px";
      this.trialDialog = true;
      this.loadTrailAccounts();
    },
    async loadTrailAccounts() {
      this.loadingTrial = true;
      try {
        const {
          data: { list: accounts },
        } = await getBuyAccountList({ platformType: this.data.platformType, auditStatus: 'SUCCESS' });
        this.loadingTrial = false;
        this.buyAccountList = accounts.filter((item) => item.status == 1);
      } finally {
        this.loadingTrial = false;
      }
    },
    // 买号点击
    async buyFun(account) {
      await applyTrail({
        taskId: this.data.id,
        buyAccountId: account.id,
      });

      this.$message.success("申请成功");
      this.trialDialog = false;
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="less" scoped>
.margin-bottom-5px {
  margin-bottom: 5px;
}
.item-padding {
  padding: 0 0.5rem;
}

.item {
  background-color: #fff;
  margin-bottom: 1rem;

  .content {
    padding-bottom: 0.5rem;
    .thumb {
      display: block;
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      .margin-bottom-5px;
      .item-padding;
      padding-top: 0.5rem;
    }
    .price {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: space-between;
      .item-padding;
    }

    .tags {
      .item-padding;
    }

    .btns {
      text-align: center;
      padding: 1rem;
      display: block;
    }
    .mob-btns,
    .mob-meta {
      display: none;
    }
  }

  // 媒体查询
  @media (max-width: 520px) {
    .content {
      .mob-btns,
      .mob-meta {
        display: block;
      }
      .btns,
      .pc-meta {
        display: none;
      }

      .mob-btns {
        padding: 0.5rem;
      }

      .meta {
        padding: 1rem;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .thumb {
          position: relative;
          min-height: 60px;
          width: 80px;
          padding: 0;
          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 6px;
          }
        }
        .mob-meta {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
      }
    }
    .thumb {
      display: none;
    }
  }
}
</style>
