import { render, staticRenderFns } from "./process_i.vue?vue&type=template&id=131d6378&scoped=true"
import script from "./process_i.vue?vue&type=script&lang=js"
export * from "./process_i.vue?vue&type=script&lang=js"
import style0 from "./process_i.vue?vue&type=style&index=0&id=131d6378&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131d6378",
  null
  
)

export default component.exports