<template>
  <div class="header">
    <div class="top">
      <div class="v-container">
        <div class="top-main">
          <!-- <img src="../../assets/image/back.png" style="width: 30px; height: 30px;"> -->
          <div class="logo">
            <img src="@/assets/image/logo.png" alt="" />
          </div>
          <div class="user">
            <img class="avatar" :src="$store.state.user.avatar" alt="" v-if="userInfo.isLogin" />
            <div class="text">
              <div v-if="userInfo.isLogin">
                {{ $store.state.user.role == "SELLER" ? "商家" : "试客" }}
              </div>
              <div v-else>未登录</div>
              <div v-if="userInfo.isLogin">{{ $store.state.user.username }}</div>
            </div>
            <div class="btn">
              <el-button type="primary" size="small" @click="logout" v-if="userInfo.id !== 0"> 退出 </el-button>
              <el-button type="primary" size="small" @click="login" v-else> 登录 </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="nav" :style="shadowBox ? 'box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);' : ''">
      <div class="v-container">
        <div class="nav-main">
          <div class="nav-item" v-if="role == 'SELLER'">
            <router-link to="/seller/trial">已发布的试用</router-link>
          </div>
          <div class="nav-item" v-if="role == 'BUYER'">
            <router-link to="/trial">试用大厅</router-link>
          </div>
          <div class="nav-item">
            <router-link to="/popularize">推广奖励</router-link>
          </div>

          <div class="nav-item" v-if="role == 'BUYER'" @click="$router.push('/user/trial')">
            <router-link to="/user/trial">已申请的试用</router-link>
          </div>
          <div class="nav-item" v-if="role == 'BUYER'" @click="$router.push('/buy')">
            <router-link to="/buy">买号管理</router-link>
          </div>
          <div class="nav-item" v-if="role == 'SELLER'" @click="$router.push('/seller/bindshop')">
            <router-link to="/seller/bindshop">店铺管理</router-link>
          </div>
          <div class="nav-item" v-if="role == 'SELLER'" @click="$router.push('/seller/template')">
            <router-link to="/seller/template">模板管理</router-link>
          </div>
          <div class="nav-item" v-if="role == 'SELLER'" @click="$router.push('/seller/blacklist')">
            <router-link to="/seller/blacklist">黑名单管理</router-link>
          </div>
          <div class="nav-item" @click="$router.push('/user/billing')">
            <router-link to="/user/billing">资金管理</router-link>
          </div>
          <!-- <div class="nav-item" @click="$router.push('/user/message')">
            <router-link to="/user/message">站内信</router-link>
          </div> -->
          <div class="nav-item" @click="$router.push('/user/index')">
            <router-link to="/user/index">会员中心</router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  props: {
    shadowBox: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    role() {
      return this.$store.state.user.role;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("user/LOGOUT");
      this.$message.success("退出成功");
      this.$router.push("/login");
    },
    login() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 998;
  .top {
    background-color: #fff;
    .top-main {
      height: 90px;
      line-height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        img {
          width: 100%;
          display: block;
        }
      }
      .user {
        display: flex;
        align-items: center;
        grid-gap: 2rem;
        .avatar {
          display: block;
          width: 50px;
          height: 50%;
          border-radius: 100%;
          border: 1px #fff solid;
          box-shadow: 0 3px 7px #bfbfbf;
        }
        .text {
          height: 100%;
          line-height: 150%;
        }
        .btn > button {
          width: 80px;
          border-radius: 22px;
        }
      }
    }
  }
  .nav {
    height: 50px;
    line-height: 50px;
    background-color: #0082f6;
    .nav-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 2rem;
      .nav-item {
        flex-grow: 1;
        text-align: center;
        display: flex;
        a {
          text-decoration: none;
          color: #fff;
          display: flex;
          flex-grow: 1;
          align-self: center;
          justify-content: center;

          &.router-link-active {
            color: #fff;
            background-color: #0069d9;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .header {
    .top {
      .top-main {
        height: 60px;
        line-height: 60px;
        justify-content: center;
        .logo {
          img {
            max-width: 100%;
            max-height: 60px;
            display: block;
          }
        }
        .user {
          display: none;
        }
      }
    }
    .nav {
      display: none;
    }
  }
}
</style>
