<template>
  <div>
    <div class="item">
      <div class="meta_bar">
        <div class="meta_item" style="display: flex; align-items: center">
          <span>模板名称：</span>
          <el-input class="w-140px mr-2" size="small" v-model="templateName" />
          <el-button type="primary" size="small" @click="updateTemplateName">保存</el-button>
        </div>
        <div class="meta_item text-blue-400 ml-4">
          试用编号：
          <span class="text-red-400">{{ data.id }}</span>
          【{{ data.platformType | filterPlatform }}】
        </div>

        <div class="meta_item mr-4">发布时间：{{ data.publishTime }}</div>
      </div>
      <div class="content">
        <div class="content_main">
          <div class="content_info">
            <el-image 
              class="thumb self-start"
              :src="(data.imgs.split(','))[0]" 
              :preview-src-list="(data.imgs.split(','))">
            </el-image>
            <div class="data">
              <div class="data_header">
                <div class="data_item">
                  店铺：<span>{{ data.shop?.name }}</span>
                </div>
                <div class="data_item">
                  宝贝单价：<span>{{ data.goodsPrice | priceFormat }}</span>
                  元
                </div>
                <div class="data_item">
                  下单总价：<span>{{ data.actualGoodsFee ? data.actualGoodsFee : data.goodsFee | priceFormat }}</span
                  >元
                </div>
                <div class="data_item">
                  奖励：<span>{{ data.buyerCommissionFee | priceFormat }}</span>
                  元
                </div>
                <div class="data_item">
                  订单号：<span>{{ data.ordersId }}</span>
                </div>
                <div class="data_item">
                  买号：<span>{{ data.buyAccount?.account }}</span>
                </div>
              </div>
              <div class="data_tag">
                <tag v-for="(item, index) in tags" :key="index">
                  {{ item }}
                </tag>
              </div>
            </div>
          </div>
          <div class="content_action">
            <div class="content_action_btns">
              <div>
                <el-button class="form-btn" type="success" size="small" @click="toPublish">发布试用</el-button>
              </div>
              <div>
                <el-button class="form-btn" type="danger" size="small" @click="deleteTemplate">删除模板</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tag from "@/components/tag/Tag.vue";
import filter from "@/plugin/filter";
import { deleteTemplate, updateTemplateName } from "@/api/task";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    tag,
  },
  data() {
    return {
      isMobile: false,
      templateName: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.templateName = this.data.templateName;
    });
    this.isMobile = document.documentElement.clientWidth <= 520;
  },
  computed: {
    role() {
      return this.$store.state.user.role;
    },
    tags() {
      return filter.filterTags(this.data);
    },
    status() {
      const statusMap = {
        WAITING_APPLY: "待申请",
        WAITING_AUDIT: "待审核",
        TOOK: "已接单",
        ORDERED: "已下单",
        GOODS_RECEIVED: "已确认收货",
        FINISHED: "已完成",
        COMPLAIN: "投诉中",
      };
      // 根据当前状态返回当前状态以及之前的状态
      const i = Object.keys(statusMap).indexOf(this.data.status);
      return Object.keys(statusMap)
        .map((item, index) => {
          if (index > i) {
            return undefined;
          }
          return {
            active: this.data.status === item,
            name: statusMap[item],
            index,
          };
        })
        .filter((item) => item !== undefined);
    },
  },
  methods: {
    async updateTemplateName() {
      await updateTemplateName(this.data.id, this.templateName);
      this.$message({
        type: "success",
        message: "修改成功",
      });
    },
    deleteTemplate() {
      this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await deleteTemplate(this.data.id);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.$emit("refresh");
      });
    },
    toPublish() {
      this.$router.push({
        path: "/seller/trialCreate",
        query: {
          templateId: this.data.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  .meta_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dadada;
    padding: 1rem;
    margin-bottom: 0.5rem;
    // .meta_item {}
  }

  .content {
    padding: 0 1rem;

    .content_main {
      display: flex;
      border-bottom: 1px solid #dadada;
      align-items: center;

      .content_info {
        flex: 1;
        display: flex;
        align-items: center;
        border-right: 1px solid #dadada;
        padding: 1rem 0;

        .thumb {
          width: 100px;
        }
        .data {
          padding-left: 1rem;
          flex: 1;

          .data_header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;
            justify-content: space-between;
            margin-bottom: 1rem;
            // .data_item {}
            .mob_meta_item {
              display: none;
            }
          }
          .data_tag {
            padding: 0.5rem 0;
          }
        }
      }
      .content_status {
        width: 200px;
        text-align: center;
        border-right: 1px solid #dadada;
        font-size: 0.5rem;
        .mob_status_label {
          display: none;
        }
      }
      .content_action {
        text-align: center;
        width: 200px;
        .content_action_btns {
          line-height: 2.2rem;
        }
        .form-btn {
          padding: 8px 15px;
          width: 140px;
          border-radius: 12px;
        }
      }
    }

    .content_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .form {
        /deep/ .el-form-item {
          margin: 0;
        }
        .form-input {
          width: 200px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .item {
    .meta_bar {
      display: none;
    }

    .content {
      padding: 0 1rem;

      .content_main {
        border-bottom: none;
        flex-direction: column;
        align-items: flex-start;

        .content_info {
          align-items: flex-start;
          border-right: none;

          .thumb {
            width: 50px;
          }
          .data {
            .data_header {
              grid-template-columns: repeat(1, 1fr);

              .mob_meta_item {
                display: block;
              }
            }
            .data_tag {
              padding: 0;
            }
          }
        }
        .content_status {
          width: 100%;
          text-align: left;
          border-right: none;
          display: flex;
          .mob_status_label {
            display: block;
            margin-right: 0.5rem;
          }

          .content_status_item,
          .content_status_item_t {
            text-align: center;
            margin: 0 0 5px;
            width: 120px;
          }
        }
        .content_action {
          width: 100%;
          margin: 1rem 0;
          .content_action_btns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            justify-content: space-between;
            align-items: center;
            line-height: 0;
          }
          .pc-content_action_btns {
            display: none;
          }
          .mob-content_action_btns {
            display: block;
          }
          .form-btn {
            padding: 10px 15px;
            width: 100%;
            border-radius: 3px;
          }
        }
      }

      .content_footer {
        display: none;
      }
    }
  }
}
</style>
