import { render, staticRenderFns } from "./Withdrawal.vue?vue&type=template&id=b76c7ba4&scoped=true"
import script from "./Withdrawal.vue?vue&type=script&lang=js"
export * from "./Withdrawal.vue?vue&type=script&lang=js"
import style0 from "./Withdrawal.vue?vue&type=style&index=0&id=b76c7ba4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b76c7ba4",
  null
  
)

export default component.exports