<template>
  <el-upload
    class="avatar-uploader"
    action="/"
    :before-upload="beforeUpload"
    :on-success="handleSuccess"
    :on-error="handleError"
    :auto-upload="true"
    :show-file-list="false"
    :list-type="listType"
  >
    <video
      v-if="imageUrl !== '' && listType == 'video'"
      :src="imageUrl"
      class="avatar !w-500px !h-auto"
      @click.stop=""
      controls
    />
    <el-image
      v-else-if="imageUrl !== ''"
      :src="imageUrl"
      class="avatar"
      @click.stop=""
      :preview-src-list="previewList"
    />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    <i v-if="imageUrl !== '' && showDelete" class="el-icon-delete delete-icon" @click.stop="handleRemove"></i>
  </el-upload>
</template>

<script>
import OSS from "ali-oss";
import { upload as QiniuUpload } from "qiniu-js";
import request from "@/plugin/request";

export default {
  name: "OSSUploader",
  props: {
    value: {
      type: String,
      default: "",
    },
    listType: {
      type: String,
      default: "picture",
    },
    type: {
      type: String,
      default: "",
    },
    showDelete: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      imageUrl: "",
      client: null,
      bucket: "wksypt",
      accessKeyId: "LTAI5tExu3M6k9qumsviVUim",
      accessKeySecret: "vtmEkRRkW1eoLukiyJktXwxV50IsiA",
      region: "oss-cn-hongkong",

      cos: '',
      tencentOssSign: {},
    };
  },
  computed: {
    config() {
      return this.$store.state.config.config;
    },
    interface() {
      if(this.config.IMG_SERVER == "qiniu"){
        return "qiniu"
      }else if(this.config.IMG_SERVER == "aliyun"){
        return "oss"
      }else{
        return "cos"
      }
    },
    previewList() {
      return this.imageUrl ? [this.imageUrl] : [];
    },
  },
  watch: {
    value(val) {
      this.imageUrl = val;
    },
  },
  mounted() {
    const { accessKeyId, accessKeySecret, region, bucket } = this;
    this.client = new OSS({
      accessKeyId,
      accessKeySecret,
      region,
      bucket,
      authorizationV4: true,
    });
    // 使用腾讯云oss时要初始化调用，有简单和安全两种初始化方式
    this.initTencentOssSimple()
    this.imageUrl = this.value;
  },
  methods: {
    initTencentOssSimple() {
      var COS = require('cos-js-sdk-v5')
      this.cos = new COS({
          SecretId: 'AKIDpUyGKBvb87wdCZm3EE4WpxufLvJWZcVB',// process.env.TENCENT_SECRET_ID,
          SecretKey: 'XwXw879GF1pSs79zc2IOK3Zt063d1B8s'// process.env.TENCENT_SECRET_KEY
      })
      this.tencentOssSign = {
        bucket: 'wksypt-1317855146',// process.env.TENCENT_COS_BUCKET
        region: 'ap-guangzhou',// process.env.TENCENT_COS_REGION
      }
    },
    // 从服务端获取签名进行cos初始化
    async initTencentOss() {
      var that = this
      let ret = await request.post("/tencentyun/getCosSign")
      console.log(ret)
      that.tencentOssSign = ret.data
      var COS = require('cos-js-sdk-v5')
      that.cos = new COS({
        getAuthorization: function (options, callback) {
          console.info(options, 9989898);
          var credentials = that.tencentOssSign.signData.credentials
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            SecurityToken: credentials.sessionToken,
            StartTime: that.tencentOssSign.signData.startTime,
            ExpiredTime: that.tencentOssSign.signData.expiredTime,
          })
        }
      })
    },
    async beforeUpload(file) {
      const ext = file.type.split("/")[1];
      const filename = `${Date.now()}${parseInt(Math.random(0, 1) * 10000000)}.${ext}`;
      if (this.interface == "oss") {
        const { client, bucket } = this;

        console.log('filepath ' + this.type)
        try {
          console.log("start upload");
          const result = await client.put(this.type == '' ? filename : this.type + '/' + filename, file);
          this.imageUrl = `https://${bucket}.${client.options.region}.aliyuncs.com/${result.name}`;
        } catch (e) {
          console.log(e);
          return false;
        }
        return false;
      } else if(this.interface == "cos"){
        let that = this
        this.cos.putObject({
          Bucket: this.tencentOssSign.bucket,
          Region: this.tencentOssSign.region,
          Key: this.type == '' ? filename : this.type + '/' + filename,// 路径 + 文件名         
          StorageClass: 'MAZ_STANDARD',
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
          }
        }, function (err, data){
          if (data) {
            if (data.statusCode == 200) {
              that.imageUrl = 'http://' + data.Location
              that.$emit("input", that.imageUrl);
            }
          }
        })
        return false;
      } else {
        try {
          const { token, domain } = await this.getQiNiuToken();
          const observable = QiniuUpload(file, `${this.type == '' ? '' : this.type + '/'}${filename}`, token);
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const that = this;
          const observer = {
            next(res) {
              console.log(res);
            },
            error(err) {
              console.log(err);
            },
            complete(res) {
              console.log(res);
              that.imageUrl = `${domain}/${res.key}`;
              that.$emit("input", that.imageUrl);
            },
          };
          observable.subscribe(observer);
        } catch (e) {
          console.log(e);
        }
        return false;
      }
    },
    async getQiNiuToken() {
      const { data } = await request.post("/qiniuyun/getOssSign");
      return {
        token: data.token,
        domain: data.domain,
      };
    },
    handleError() {
      if (this.interface == "oss") {
        this.$emit("input", this.imageUrl);
      }
    },
    handleSuccess() {
      if (this.interface == "oss") {
        this.$emit("input", this.imageUrl);
      }
    },
    handleRemove() {
      this.imageUrl = "";
      this.$emit("input", this.imageUrl);
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  border-radius: 50%;
  color: white;
  background-color: #f56c6c;
  cursor: pointer;
}
</style>
